import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Container, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { boxStyle, fieldStyle, labelStyle, buttonStyle } from '../../Styles/FormStyles'
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function LocationForm({handleClose, open, fetchLocations}) {
    // State to hold the input values
    const [locationName, setLocationName] = useState('');
    const [address, setAddress] = useState('');
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    const companyId = 1;

    // Handle the form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const requestBody = {
            companyId,
            locationName,
            address
        };

        try {
            const token = await user.getIdToken()
            const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
            const response = await fetch(liveEventsEndpoint + 'api/locations', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Error in API call');
            }
            fetchLocations()
            handleClose()
            const responseData = await response.json();
            console.log('Location saved:', responseData);
            // Handle success (show success message, clear form, etc.)
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error (show error message, etc.)
        }
    };

    return (
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    style={boxStyle}
                >
                    <Typography variant="h6" align="center" style={labelStyle}>
                        Location Form
                    </Typography>
                    <TextField
                        label="Location Name"
                        variant="outlined"
                        value={locationName}
                        style={fieldStyle}
                        onChange={(e) => setLocationName(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Address"
                        variant="outlined"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={fieldStyle}
                        fullWidth
                    />
                    <Button type="submit" variant="contained" color="primary" style = {buttonStyle}>
                        Submit
                    </Button>
                </Box>
            </Modal>
    );
}

export default LocationForm;
