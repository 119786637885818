import React, { useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, TextField, Button, Select, MenuItem, FormControl, Box, Grid, InputLabel } from '@mui/material';
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function ShiftBuilder() {
  const { eventId } = useParams(); 
  const location = useLocation();
  const { zones, users } = location.state || { zones: [], users: [] };
  const [shiftDetails, setShiftDetails] = useState({
    shiftName: '',
    startTime: '',
    endTime: '',
    shiftUserZoneAssignments: []
  });

  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  console.log(shiftDetails)

  const handleInputChange = (e) => {
    setShiftDetails({ ...shiftDetails, [e.target.name]: e.target.value });
  };

  const handleAssignmentChange = (index, key, value) => {
    const newAssignments = [...shiftDetails.shiftUserZoneAssignments];
    newAssignments[index] = { ...newAssignments[index], [key]: value };
    setShiftDetails({ ...shiftDetails, shiftUserZoneAssignments: newAssignments });
  };

  const addShiftUserZoneAssignment = () => {
    setShiftDetails({
      ...shiftDetails,
      shiftUserZoneAssignments: [...shiftDetails.shiftUserZoneAssignments, { userId: '', zoneId: '' }]
    });
  };

  const removeShiftUserZoneAssignment = (index) => {
    const newAssignments = [...shiftDetails.shiftUserZoneAssignments];
    newAssignments.splice(index, 1);
    setShiftDetails({ ...shiftDetails, shiftUserZoneAssignments: newAssignments });
  };

  console.log("shiftDetails",shiftDetails);


  const handleSubmit = async () => {
    const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
    const endpoint = liveEventsEndpoint + 'api/shifts/create';


    // Include eventId in the request body
    const payload = {
      ...shiftDetails,
      eventId, // Add this line to include eventId
    };

    try {
      const token = await user.getIdToken()
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // Handle response
      
      console.log('Shift created successfully');
      navigate(-1)
      // Navigate back or show success message
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <TextField label="Shift Name" name="shiftName" value={shiftDetails.shiftName} onChange={handleInputChange} fullWidth margin="normal" />
            <label htmlFor="endTime" style={{ marginBottom: '8px' }}>Start Time</label>
            <TextField type="datetime-local" name="startTime" value={shiftDetails.startTime} onChange={handleInputChange} fullWidth margin="normal" />
            <label htmlFor="endTime" style={{ marginBottom: '8px' }}>End Time</label>
            <TextField type="datetime-local" name="endTime" value={shiftDetails.endTime} onChange={handleInputChange} fullWidth margin="normal" />
            
            {shiftDetails.shiftUserZoneAssignments.map((assignment, index) => (
              <Box key={index} display="flex" alignItems="center" marginY={2}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>User</InputLabel>
                  <Select value={assignment.userId} onChange={(e) => handleAssignmentChange(index, 'userId', e.target.value)}>
                    {users.map((user) => (
                      <MenuItem key={user.userId} value={user.userId}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Zone</InputLabel>
                  <Select value={assignment.zoneId} onChange={(e) => handleAssignmentChange(index, 'zoneId', e.target.value)}>
                    {zones.map((zone) => (
                      <MenuItem key={zone.zoneId} value={zone.zoneId}>{zone.zoneName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button variant="contained" color="secondary" onClick={() => removeShiftUserZoneAssignment(index)}>Remove Assignment</Button>
              </Box>
            ))}
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Button variant="contained" color="secondary" onClick={addShiftUserZoneAssignment}>Add Assignment</Button>
            </Box>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>Create Shift</Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ShiftBuilder;
