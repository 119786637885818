import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography, Container, Modal } from '@mui/material';
import { boxStyle, fieldStyle, labelStyle, buttonStyle } from '../../Styles/FormStyles'
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function UserForm({ handleClose, open, locationId, fetchData }) {
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const requestBody = {
            companyId: 1,
            locationId,
            role,
            email,
            firstName,
            lastName
        };

        try {
            const token = await user.getIdToken()
            const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
            const response = await fetch(liveEventsEndpoint + 'api/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Error in API call');
            }
            fetchData(locationId)
            handleClose()
            const responseData = await response.json();
            console.log('User created:', responseData);
            // Handle success (e.g., clear form, show success message)
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error (e.g., show error message)
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box
                component="form"
                onSubmit={handleSubmit}
                style={boxStyle}
            >
                <Typography style = {labelStyle} variant="h6">User Form</Typography>
                <TextField
                    style = {fieldStyle}
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
                <TextField
                    style = {fieldStyle}
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                />
                <TextField
                    style = {fieldStyle}
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                />
                <TextField
                    style = {fieldStyle}
                    label="Role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    fullWidth
                />
                <Button type="submit" variant="contained" color="primary" style = {buttonStyle}>
                    Submit
                </Button>
            </Box>
        </Modal>
    );
}

export default UserForm;
