import React from 'react';
import './Navbar.css'; // Make sure to create this CSS file
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faCog } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({handleLogout}) => {
  const navigate = useNavigate();
  return (
    <nav className="navbar">
      <div className="navbar-brand" style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>Stadium Now</div>
      <div className="navbar-icons">
        <FontAwesomeIcon icon={faHome} style={{ cursor: 'pointer' }} onClick={() => navigate('/')}/>
        <FontAwesomeIcon icon={faUser} onClick={handleLogout}/>
        <FontAwesomeIcon icon={faCog} />
        </div>
    </nav>
  );
};

export default Navbar;
