// ShiftUpdater.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, TextField, Button, Select, MenuItem, FormControl, Box, Grid, InputLabel } from '@mui/material';
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function ShiftUpdater() {
  const { shiftId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { shiftData, zones, users } = location.state; // Received from EventDashboard

  const [shiftDetails, setShiftDetails] = useState({
    shiftId: shiftData.shiftId,
    eventId: shiftData.eventId,
    shiftName: shiftData.shiftName,
    startTime: shiftData.startTime,
    endTime: shiftData.endTime,
    shiftUserZoneAssignments: []
  });

  const [user] = useAuthState(auth);

  

  
useEffect(() => {
    const fetchAssignments = async () => {
      const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
      const endpoint = `${liveEventsEndpoint}api/shift-user-assignments/shift/${shiftId}`;
  
      try {
        const token = await user.getIdToken()
        const response = await fetch(endpoint, {
          method: 'GET', // or 'POST', depending on your API
          headers: {
            'Authorization': `Bearer ${token}`, // Assuming your token is a bearer token
            'Content-Type': 'application/json', // Add this line if your API expects JSON
            // You can add other headers here as needed
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const assignments = await response.json();
        // Transform assignments to include user and zone details
        const transformedAssignments = assignments.map(assignment => ({
          ...assignment,
          userName: users.find(user => user.userId === assignment.userId)?.firstName + ' ' + users.find(user => user.userId === assignment.userId)?.lastName,
          zoneName: zones.find(zone => zone.zoneId === assignment.zoneId)?.zoneName
        }));
  
        setShiftDetails(s => ({ ...s, shiftUserZoneAssignments: transformedAssignments }));
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
    };
  
    fetchAssignments();
  }, [shiftId, zones, users]);

  console.log("shiftDetails", shiftDetails)

  const handleInputChange = (e) => {
    setShiftDetails({ ...shiftDetails, [e.target.name]: e.target.value });
  };

  const handleAssignmentChange = (index, key, value) => {
    const newAssignments = [...shiftDetails.shiftUserZoneAssignments];
    newAssignments[index] = { ...newAssignments[index], [key]: value };
    setShiftDetails({ ...shiftDetails, shiftUserZoneAssignments: newAssignments });
  };

  const addShiftUserZoneAssignment = () => {
    setShiftDetails({
      ...shiftDetails,
      shiftUserZoneAssignments: [...shiftDetails.shiftUserZoneAssignments, { userId: '', zoneId: '' }]
    });
  };

  const removeShiftUserZoneAssignment = (index) => {
    const newAssignments = [...shiftDetails.shiftUserZoneAssignments];
    newAssignments.splice(index, 1);
    setShiftDetails({ ...shiftDetails, shiftUserZoneAssignments: newAssignments });
  };
  
  

  const handleSubmit = async () => {
    const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
    const endpoint = liveEventsEndpoint + 'api/shifts/update';

    const payload = {
        shiftId: shiftDetails.shiftId,
        eventId: shiftDetails.eventId,
        shiftName: shiftDetails.shiftName,
        startTime: shiftDetails.startTime,
        endTime: shiftDetails.endTime,
        shiftUserZoneAssignments: shiftDetails.shiftUserZoneAssignments
      };

    try {
      const token = await user.getIdToken()
      const response = await fetch(endpoint, {
        method: 'PUT', // Assuming the method to update is PUT
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('Shift updated successfully');
      navigate(-1); // Go back to the previous page
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <TextField label="Shift Name" name="shiftName" value={shiftDetails.shiftName} onChange={handleInputChange} fullWidth margin="normal" />
            <label htmlFor="endTime" style={{ marginBottom: '8px' }}>Start Time</label>
            <TextField type="datetime-local" name="startTime" value={shiftDetails.startTime} onChange={handleInputChange} fullWidth margin="normal" />
            <label htmlFor="endTime" style={{ marginBottom: '8px' }}>End Time</label>
            <TextField type="datetime-local" name="endTime" value={shiftDetails.endTime} onChange={handleInputChange} fullWidth margin="normal" />
            
            {shiftDetails.shiftUserZoneAssignments.map((assignment, index) => (
              <Box key={index} display="flex" alignItems="center" marginY={2}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>User</InputLabel>
                  <Select value={assignment.userId} onChange={(e) => handleAssignmentChange(index, 'userId', e.target.value)}>
                    {users.map((user) => (
                      <MenuItem key={user.userId} value={user.userId}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Zone</InputLabel>
                  <Select value={assignment.zoneId} onChange={(e) => handleAssignmentChange(index, 'zoneId', e.target.value)}>
                    {zones.map((zone) => (
                      <MenuItem key={zone.zoneId} value={zone.zoneId}>{zone.zoneName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button variant="contained" color="secondary" onClick={() => removeShiftUserZoneAssignment(index)}>Remove Assignment</Button>
              </Box>
            ))}
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Button variant="contained" color="secondary" onClick={addShiftUserZoneAssignment}>Add Assignment</Button>
            </Box>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>Update Shift</Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ShiftUpdater;
