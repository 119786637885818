// EventDashboard.js

import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Box, CircularProgress, Button } from '@mui/material';
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function EventDashboard() {
  const [shifts, setShifts] = useState(null);
  const { eventId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const { zones, users } = location.state || { zones: [], users: [] };

  const [user] = useAuthState(auth);

  console.log(shifts)

  useEffect(() => {
    async function fetchShifts() {
      try {
        const token = await user.getIdToken()
        const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
        const response = await fetch(liveEventsEndpoint + `api/shifts/event/${eventId}`,{
          method: 'GET', // or 'POST', depending on your API
          headers: {
            'Authorization': `Bearer ${token}`, // Assuming your token is a bearer token
            'Content-Type': 'application/json', // Add this line if your API expects JSON
            // You can add other headers here as needed
          },
        });
        const data = await response.json();
        setShifts(data);
      } catch (error) {
        console.error("Error fetching shifts:", error);
      }
    }

    fetchShifts();
  }, [eventId]);

  if (!shifts) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
    <Button variant="contained" color="primary" onClick={() => navigate(`/shiftBuilder/${eventId}`, { state: { zones, users } })}>Create New Shift</Button>
    <Grid container spacing={4} style={{ margin: '20px auto', maxWidth: '90%', padding: '20px' }}>
      {shifts.map((shift, index) => (
        <Grid item xs={12} key={index}>
          <Card style={{ padding: '10px' }}>
            <CardContent>
              <Typography variant="h5">{shift.shiftName}</Typography>
              <Typography variant="subtitle1">{`Start: ${shift.startTime} - End: ${shift.endTime}`}</Typography>
              <Button variant="contained" color="secondary" onClick={() => navigate(`/shiftUpdater/${shift.shiftId}`, { state: { shiftData: shift, zones, users } })}>
                Update Shift
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    </Box>
  );
}

export default EventDashboard;
