import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableHead, TableRow, Grid, TextField, Box, CircularProgress, Button, IconButton } from '@mui/material';
import UserForm from '../Forms/UserForm';
import ZoneForm from '../Forms/ZoneForm';
import EventForm from '../Forms/EventForm';
import AddIcon from '@mui/icons-material/Add';
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
function LocationDashboard() {
  const { locationId } = useParams();
  const [locationData, setLocationData] = useState(null);
  const navigate = useNavigate();
  
  const [openUser, setOpenUser] = useState(false);
  const handleOpenUser = () => setOpenUser(true);
  const handleCloseUser = () => setOpenUser(false);

  const [openZone, setOpenZone] = useState(false);
  const handleOpenZone = () => setOpenZone(true);
  const handleCloseZone = () => setOpenZone(false);

  const [openEvent, setOpenEvent] = useState(false);
  const handleOpenEvent = () => setOpenEvent(true);
  const handleCloseEvent = () => setOpenEvent(false);

  console.log(locationId)

  const [user] = useAuthState(auth);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`, { state: { zones, users } });
  };
  // State for search queries
  const [eventSearch, setEventSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [zoneSearch, setZoneSearch] = useState('');

  

  useEffect(() => {
    if (locationId) {
      const loadLocationData = async () => {
        await fetchData(locationId);
      };

      loadLocationData();
    }
  }, [locationId]);

  async function fetchData(locationId) {
    const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
    const url = liveEventsEndpoint + `api/locations/location/${locationId}`;
    try {
      const token = await user.getIdToken()
      const response = await fetch(url , {
        method: 'GET', // or 'POST', depending on your API
        headers: {
          'Authorization': `Bearer ${token}`, // Assuming your token is a bearer token
          'Content-Type': 'application/json', // Add this line if your API expects JSON
          // You can add other headers here as needed
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setLocationData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }

  if (!locationData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleAddNew = (type) => {
      navigate(`/${type}Form`, { state: { locationId } });
  };

  const { location, events, users, zones } = locationData;

  // Filter functions
  const filterEvents = events.filter(event => 
    event.eventName.toLowerCase().includes(eventSearch.toLowerCase())
  );
  const filterUsers = users.filter(user => 
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(userSearch.toLowerCase())
  );
  const filterZones = zones.filter(zone => 
    zone.zoneName.toLowerCase().includes(zoneSearch.toLowerCase())
  );

  // Style for scrollable table
  const tableStyle = {
    maxHeight: '300px',
    minHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  const cellSyle = {
    fontFamily: ['Segoe UI', 'Ubuntu', 'Roboto', 'Open Sans', 'Helvetica Neue', 'sans-serif']
  }


  const renderTable = (data, columns) => (
    <Table size="small" style={{ ...tableStyle }}>
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell style = {cellSyle} key={column}>{column}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={index}>
            {columns.map(column => (
              <TableCell style = {cellSyle} key={`${index}-${column}`}>{item[column]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const renderHeaderWithSearch = (title, searchState, setSearchState) => (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography style = {cellSyle} variant="h6">{title}</Typography>
      <TextField
        size="small"
        label={`Search ${title}`}
        variant="outlined"
        value={searchState}
        onChange={(e) => setSearchState(e.target.value)}
        style={{ ...cellSyle, width: '30%' }}
      />
    </Box>
  );

  const userTableData = filterUsers.map(user => ({
    'First Name': user.firstName,
    'Last Name': user.lastName,
    Email: user.email
  }));

  const zoneTableData = filterZones.map(zone => ({
    'Zone Name': zone.zoneName,
    Latitude: zone.latitude,
    Longitude: zone.longitude
  }));

  return (
    <Grid container spacing={4} style={{ margin: '20px auto', maxWidth: '90%', padding: '20px' }}>
      {/* Location Details */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography style = {cellSyle} variant="h5">{location.locationName}</Typography>
            <Typography style = {cellSyle} variant="subtitle1">{location.address}</Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* Events Table */}
      <Grid item xs={12}>
        <Card style={{ padding: '10px', position: 'relative' }}>
          <CardContent>
            {renderHeaderWithSearch('Events', eventSearch, setEventSearch)}
            <div style={{ ...tableStyle, marginTop: '10px' }}>
              <Table style={tableStyle}>
                <TableHead>
                  <TableRow>
                    <TableCell style = {cellSyle}>Event Name</TableCell>
                    <TableCell style = {cellSyle}>Start Time</TableCell>
                    <TableCell style = {cellSyle}>End Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterEvents.map(event => (
                    <TableRow key={event.eventId} onClick={() => handleEventClick(event.eventId)} style={{ cursor: 'pointer' }}>
                      <TableCell style = {cellSyle}>{event.eventName}</TableCell>
                      <TableCell style = {cellSyle}>{new Date(event.startTime).toLocaleString()}</TableCell>
                      <TableCell style = {cellSyle}>{new Date(event.endTime).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </div>
          </CardContent>
          <Box display="flex" justifyContent="flex-end" marginTop="10px">
          <IconButton aria-label="add" onClick={handleOpenEvent} style={{ height: '50px', width: '50px' }}>
            <AddIcon style={{ fontSize: 40 }} />
          </IconButton>
          </Box>
        </Card>
        <EventForm open={openEvent} handleClose={handleCloseEvent} locationId={locationId} fetchData={fetchData}/>
      </Grid>

      {/* Users List - Left Half */}
      <Grid item xs={12} md={6}>
        <Card style={{ padding: '10px', position: 'relative' }}>
          <CardContent>
            {renderHeaderWithSearch('Users', userSearch, setUserSearch)}
            <div style={{ ...tableStyle, marginTop: '10px' }}>
            {renderTable(userTableData, ['First Name', 'Last Name', 'Email'])}
            </div>
          </CardContent>
          <Box display="flex" justifyContent="flex-end" marginTop="10px">
          <IconButton aria-label="add" onClick={handleOpenUser} style={{ height: '50px', width: '50px' }}>
            <AddIcon style={{ fontSize: 40 }} />
          </IconButton>
          </Box>
        </Card>
        <UserForm open={openUser} handleClose={handleCloseUser} locationId={locationId} fetchData={fetchData}/>
      </Grid>

      {/* Zones List - Right Half */}
      <Grid item xs={12} md={6}>
        <Card style={{ padding: '10px', position: 'relative' }}>
          <CardContent>
            {renderHeaderWithSearch('Zones', zoneSearch, setZoneSearch)}
            <div style={{ ...tableStyle, marginTop: '10px' }}>
            {renderTable(zoneTableData, ['Zone Name', 'Latitude', 'Longitude'])}
            </div>
          </CardContent>
          <Box display="flex" justifyContent="flex-end" marginTop="10px">
          <IconButton aria-label="add" onClick={handleOpenZone} style={{ height: '50px', width: '50px' }}>
            <AddIcon style={{ fontSize: 40 }} />
          </IconButton>
          </Box>
        </Card>
        <ZoneForm open={openZone} handleClose={handleCloseZone} locationId={locationId} fetchData={fetchData}/>
      </Grid>
    </Grid>
  );
}

export default LocationDashboard;

