import './global.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LocationDashboard from './Components/Pages/LocationDashboard';
import CompanyLocationsList from './Components/Pages/CompanyLocationsList';
import EventDashboard from './Components/Pages/EventDashboard';
import ShiftBuilder from './Components/Pages/ShiftBuilder';
import ShiftUpdater from './Components/Pages/ShiftUpdater';
import Navbar from './Components/Navbar/Navbar'
import { auth } from '../src/Components/Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import LoginWrapper from './Components/Authentication/LoginWrapper';
import SignUpWrapper from './Components/Authentication/SignUpWrapper';
import { Box, ThemeProvider } from "@mui/material";
function App() {
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [user] = useAuthState(auth);

  const [dbUser, setDbUser] = useState({});


  console.log(user)

  const handleLogout = () => {
    auth.signOut();
  };

  async function fetchUserByEmail() {
    try {
      const token = await user.getIdToken()
      const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
      console.log("liveEventsEndpoints", liveEventsEndpoint)
      const response = await fetch(liveEventsEndpoint + `api/users/email/${user.email}`, {
        method: 'GET', // or 'POST', depending on your API
        headers: {
          'Authorization': `Bearer ${token}`, // Assuming your token is a bearer token
          'Content-Type': 'application/json', // Add this line if your API expects JSON
          // You can add other headers here as needed
        },
      });
      const data = await response.json();
      setDbUser(data);
    } catch (error) {
      console.error("Error fetching user:", error);
    } 
  }

  useEffect(() => {
    fetchUserByEmail();
  }, [user]);
  

  return (
    <>
    <Router>
      {user && !dbUser.hasOwnProperty('error') ? (
        <>
          <Navbar handleLogout={handleLogout} />
          <Box
            sx={{
              backgroundColor: '#2F4550',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              overflow: 'hidden',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {dbUser.role === 'admin' ? (
              // Routes for admin users
              <Routes>
                <Route path="/" element={<CompanyLocationsList setSelectedLocationId={setSelectedLocationId} token={user.getIdToken} />} />
                <Route path="/location/:locationId" element={<LocationDashboard />} />
                <Route path="/event/:eventId" element={<EventDashboard />} />
                <Route path="/shiftBuilder/:eventId" element={<ShiftBuilder />} />
                <Route path="/shiftUpdater/:shiftId" element={<ShiftUpdater />} />
              </Routes>
            ) : (
              // Routes for employee users
              <Routes>
                <Route path="/" element={<div>You are an employee</div>} />
                {/* Add more employee-specific routes here if necessary */}
              </Routes>
            )}
          </Box>
        </>
      ) : (
        // Routes for unauthenticated users
        <Routes>
          <Route path="/" element={<LoginWrapper />} />
          <Route path="/signup" element={<SignUpWrapper />} />
        </Routes>
      )}
    </Router>
    </>
);

}

export default App;
