import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { boxStyle, fieldStyle, labelStyle, buttonStyle, dateTimePickerStyle } from '../../Styles/FormStyles'
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function EventForm({ handleClose, open, locationId, fetchData }) {
    const [eventName, setEventName] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    console.log(locationId)

    const handleSubmit = async (event) => {
        event.preventDefault();

        const requestBody = {
            eventName,
            startTime: [startTime.getFullYear(), startTime.getMonth() + 1, startTime.getDate(), startTime.getHours(), startTime.getMinutes()],
            endTime: [endTime.getFullYear(), endTime.getMonth() + 1, endTime.getDate(), endTime.getHours(), endTime.getMinutes()],
            locationId
        };

        try {
            const token = await user.getIdToken()
            const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
            const response = await fetch(liveEventsEndpoint + 'api/events', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Error in API call');
            }
            fetchData(locationId)
            handleClose()
            const responseData = await response.json();
            console.log('Event saved:', responseData);
            // Handle success
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box component="form" onSubmit={handleSubmit} style={boxStyle}>
                <Typography style={labelStyle} variant="h6" align="center">Event Form</Typography>
                <TextField
                    style={fieldStyle}
                    label="Event Name"
                    variant="outlined"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    fullWidth
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        sx={dateTimePickerStyle}
                        label="Start Time"
                        value={startTime}
                        onChange={setStartTime}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                    <DateTimePicker
                        sx={dateTimePickerStyle}
                        label="End Time"
                        value={endTime}
                        onChange={setEndTime}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
                <Button style={buttonStyle} type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </Box>
        </Modal>
    );
}

export default EventForm;
