import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../Authentication/firebase';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CustomInput from "./CustomInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    IconButton,
    InputAdornment, 
    Box, 
    Button, 
    Checkbox, 
    colors, 
    Typography 
  } from "@mui/material";
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            navigate('/'); // Navigate to home or desired route on successful login
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    const navigateToSignUp = () => {
        navigate('/signup'); // Navigate to the SignUp page
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            alert('Email and password are required');
            return;
        }
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/'); // Navigate to home or desired route on successful login
        } catch (error) {
            console.error("Error signing in:", error);
            alert('Login failed');
        }
    };

    return (
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          minHeight={550}
          sx={{
            boxShadow: {
              xs: "",
              sm: "",
              md: "15px 2px 5px -5px",
              lg: "15px 2px 5px -5px",
              xl: "15px 2px 5px -5px",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(0, 24, 57, 0.2)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              borderRadius: {
                xs: "30px",
                sm: "30px",
                md: "30px 0 0 30px",
                lg: "30px 0 0 30px",
                xl: "30px 0 0 30px",
              },
            }}
          >
            <Box width="80%">
              <Box display="flex" flexDirection="column" alignItems="center">
                {/* LOGO */}
                <Box
                  sx={{
                    mt: "60px",
                    width: "50px",
                    height: "50px",
                    bgcolor: "primary.main",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" color="white">
                    SN
                  </Typography>
                </Box>
                {/* LOGO END */}
    
                <Typography color="white" fontWeight="400" mt={5} mb={3} fontFamily={"sans-serif"}>
                  Sign in to dashboard
                </Typography>
              </Box>
    
              {/* INPUTS */}
              <CustomInput
                label="Email"
                placeholder="Enter your email..."
                isIconActive={false}
                onChange={(e) => setEmail(e.target.value)}
              />
              <CustomInput
                label="Password"
                placeholder="Enter your password..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isIconActive={true}
                type={showPassword ? "text" : "password"}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
              />
              {/* INPUT END */}
    
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                width="100%"
                color="white"
              >
                <div style={{ display: "flex" }}>
                  <Checkbox disableRipple sx={{ p: 0, pr: 1 }} />
                  <Typography fontFamily={"sans-serif"} fontSize={16} fontWeight={400}>Remember me</Typography>
                </div>
                <Typography
                  href="#yoyo"
                  style={{
                    color: 'white',
                    textDecoration: "none",
                    fontFamily: "sans-serif",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  Forgot password?
                </Typography>
              </Box>
              <Button
                variant="contained"
                fullWidth
                onClick={handleLogin}
                sx={{ mt: 4 }}
              >
                Login
              </Button>
              <Button
                        variant="outlined"
                        fullWidth
                        onClick={signInWithGoogle}
                        sx={{ mt: 2 }}
                    >
                        Sign In with Google
                    </Button>
            </Box>
            <Box
                mt={3}
              >
                <Box style = {{display: 'flex'}}>
                <Typography
                  style={{
                    color: 'white',
                    textDecoration: "none",
                    fontFamily: "sans-serif",
                    fontWeight: 400,
                    fontSize: '14px'
                  }}
                >
                  No Account?
                </Typography>
                <Typography
                  onClick={navigateToSignUp}
                  style={{
                    color: '#03a9f4',
                    textDecoration: "none",
                    fontFamily: "sans-serif",
                    cursor: "pointer",
                    marginLeft: '5px',
                    fontWeight: 600,
                    fontSize: '14px'
                  }}
                >
                  Sign Up
                </Typography></Box>
          </Box>
          </Box>
        </Grid>
      );
};

export default Login;
