import { VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { colors } from "./theme"

const CustomInput = ({  isIconActive, label, placeholder, value, onChange, type, endAdornment  }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignContent="center"
      justifyContent="flex-start"
      mb={2}
    >
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
                <Typography color="white" pb={1} fontSize={14} fontWeight={400} fontFamily={"sans-serif"}>
                    {label}
                </Typography>
                <Paper
                    sx={{
                        background: colors.input[500],
                        width: "100%"
                    }}
                >
                    <InputBase
                        type={type}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        fullWidth
                        sx={{
                            bgcolor: colors.input[500],
                            p: 1,
                            borderRadius: "5px",
                            fontFamily: "sans-serif"
                        }}
                        endAdornment={endAdornment}
                    />
                </Paper>
            </Box>
    </Box>
  );
};

export default CustomInput;
