import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography, Modal } from '@mui/material';
import { boxStyle, fieldStyle, labelStyle, buttonStyle } from '../../Styles/FormStyles'
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function ZoneForm({ handleClose, open, locationId, fetchData }) {
    const [zoneName, setZoneName] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [radius, setRadius] = useState('');
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const requestBody = {
            locationId: parseInt(locationId, 10),
            zoneName,
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            radius: parseFloat(radius)
        };

        try {
            const token = await user.getIdToken()
            const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
            const response = await fetch(liveEventsEndpoint + 'api/zones', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Error in API call');
            }
            fetchData(locationId)
            handleClose()
            const responseData = await response.json();
            console.log('Zone saved:', responseData);
            // Handle success (e.g., clear form, show success message)
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error (e.g., show error message)
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box
                component="form"
                onSubmit={handleSubmit}
                style = {boxStyle}
            >
                <Typography style = {labelStyle} variant="h6">Zone Form</Typography>
                <TextField
                    style = {fieldStyle}
                    label="Zone Name"
                    value={zoneName}
                    onChange={(e) => setZoneName(e.target.value)}
                    fullWidth
                />
                <TextField
                    style = {fieldStyle}
                    label="Latitude"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    fullWidth
                />
                <TextField
                    style = {fieldStyle}
                    label="Longitude"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    fullWidth
                />
                <TextField
                    style = {fieldStyle}
                    label="Radius"
                    value={radius}
                    onChange={(e) => setRadius(e.target.value)}
                    fullWidth
                />
                <Button style = {buttonStyle} type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </Box>
        </Modal>
    );
}

export default ZoneForm;
