const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '20%',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: '40px'
};

const fieldStyle = {
    marginTop: '10px',
    fontFamily: ['Segoe UI', 'Ubuntu', 'Roboto', 'Open Sans', 'Helvetica Neue', 'sans-serif'],
};

const labelStyle = {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: ['Segoe UI', 'Ubuntu', 'Roboto', 'Open Sans', 'Helvetica Neue', 'sans-serif'],
    fontSize: '2em',
    fontWeight: 'lighter',
    color: '#222',
    letterSpacing: '2px'
};

const buttonStyle = {
    minWidth: '100%',
    cursor: 'pointer',
    marginRight: '0.25em',
    marginTop: '1em',
    padding: '0.938em',
    border: 'none',
    borderRadius: '6px',
    color: '#fefefe',
};

const dateTimePickerStyle = {
    marginTop: '20px',
    marginRight: '10px',
    fontFamily: ['Segoe UI', 'Ubuntu', 'Roboto', 'Open Sans', 'Helvetica Neue', 'sans-serif'],
};


export { boxStyle, fieldStyle, labelStyle, buttonStyle, dateTimePickerStyle };
