import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress, Box, Button, CardMedia, CardActionArea, CardActions, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import LocationForm from '../Forms/LocationForm';
import { auth } from '../Authentication/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function CompanyLocationsList({ onSelectLocation, setSelectedLocationId }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [user] = useAuthState(auth);

  

  const handleLocationClick = (locationId) => {
    setSelectedLocationId(locationId)
    navigate(`/location/${locationId}`);
  };
  const handleFormClick = (locationId) => {
    navigate(`/locationForm`);
  };
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  // async function fetchLocations() {
  //   const token = await user.getIdToken()
  //   const headers = {
  //     'Authorization': `Bearer ${token}`, // Assuming your token is a bearer token
  //     'Content-Type': 'application/json' // Add this line if your API expects JSON
  //   };
  //   try {
  //     const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
  //     console.log("liveEventsEndpoints",liveEventsEndpoint)
  //     const response = await fetch(liveEventsEndpoint + `api/locations/company/1`, headers);
  //     const data = await response.json();
  //     setLocations(data);
  //   } catch (error) {
  //     console.error("Error fetching locations:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  async function fetchLocations() {
    try {
      const token = await user.getIdToken()
      const liveEventsEndpoint = process.env.REACT_APP_LIVE_EVENTS_ENDPOINT;
      console.log("liveEventsEndpoints", liveEventsEndpoint)
      const response = await fetch(liveEventsEndpoint + `api/locations/company/1`, {
        method: 'GET', // or 'POST', depending on your API
        headers: {
          'Authorization': `Bearer ${token}`, // Assuming your token is a bearer token
          'Content-Type': 'application/json', // Add this line if your API expects JSON
          // You can add other headers here as needed
        },
      });
      const data = await response.json();
      setLocations(data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  }


 useEffect(() => {
    fetchLocations();
  }, []);

  console.log(locations)

  const fontStyle = {fontFamily: ['Segoe UI', 'Ubuntu', 'Roboto', 'Open Sans', 'Helvetica Neue',
  'sans-serif']}

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
    <Grid container spacing={4} style={{ margin: '20px auto', maxWidth: '80%', padding: '20px', position: 'relative' }}>
      {locations.map(location => (
        <Grid item xs={12} md={3} key={location.locationId}>
          <CardActionArea onClick={() => handleLocationClick(location.locationId)}>
          <Card >
                <CardMedia
                component="img"
                height="140"
                src="https://townsquare.media/site/701/files/2022/07/attachment-gettyimages-1288453397-1-e1658433663347.jpg?w=1200&h=0&zc=1&s=0&a=t&q=89"
                alt="Gilette Stadium"
              />
            <CardContent>
              <Typography style = {fontStyle} variant="h5">{location.locationName}</Typography>
              <Typography style = {fontStyle} variant="subtitle1">{location.address}</Typography>
              <Box display="flex" justifyContent="flex-end" marginTop="10px">
              </Box>
            </CardContent>
          </Card>
          </CardActionArea>
          <CardActions>
      </CardActions>
        </Grid>
      ))}
      <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton aria-label="add" onClick={handleOpen} style={{ height: '50px', width: '50px' }}>
            <AddIcon style={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
    </Grid>
    <LocationForm open={open} handleClose={handleClose} fetchLocations={fetchLocations}/>
    </Box>
  );
}

export default CompanyLocationsList;
